body,
html {
  height: 100%;
}

.bg {
  /* The image used */
  background-image: url("../../images/bg12.jpg");
  /* background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg"); */
  opacity: 1;
  /* Full height */
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg_index {
  /* The image used */
  background-image: url("../../images/bg36.jpg");
  /* background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg"); */
  opacity: 1;
  /* Full height */
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
}
